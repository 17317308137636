<template>
    <b-container class="bg-white p-10">
        <b-row>
            <h1 class="text-center font-size-lg m-auto">Contact Us</h1>
        </b-row>
        <b-row>
            <form @submit.prevent="handleSubmitOperation" autocomplete="off" class="m-auto p-10">
                <b-row>
                    <b-col sm="6">
                        <b-form-group
                            label="Full Name *"
                            label-for="name"
                            :invalid-feedback="formErrors.first('name')"
                        >
                            <b-form-input
                                id="name"
                                v-model="formFields.name"
                                type="text"
                                :state="((formErrors.has('name') ? false : null))"
                            ></b-form-input>
                        </b-form-group>
                    </b-col><!--/b-col-->
                    <b-col sm="6">
                        <b-form-group
                            label="Email *"
                            label-for="email"
                            :invalid-feedback="formErrors.first('email')"
                        >
                            <b-form-input
                                id="email"
                                v-model="formFields.email"
                                type="text"
                                :state="((formErrors.has('email') ? false : null))"
                            ></b-form-input>
                        </b-form-group>
                    </b-col><!--/b-col-->
                    <b-col sm="6">
                        <b-form-group
                            label="Phone *"
                            label-for="phone"
                            :invalid-feedback="formErrors.first('phone')"
                        >
                            <b-form-input
                                id="phone"
                                v-model="formFields.phone"
                                type="text"
                                :state="((formErrors.has('phone') ? false : null))"
                            ></b-form-input>
                        </b-form-group>
                    </b-col><!--/b-col-->
                    <b-col sm="6">
                        <b-form-group
                            label="Subject *"
                            label-for="subject"
                            :invalid-feedback="formErrors.first('subject')"
                        >
                            <b-form-input
                                id="subject"
                                v-model="formFields.subject"
                                type="text"
                                :state="((formErrors.has('subject') ? false : null))"
                            ></b-form-input>
                        </b-form-group>
                    </b-col><!--/b-col-->
                    <b-col sm="12">
                        <b-form-group
                            label="Message *"
                            label-for="message"
                            :invalid-feedback="formErrors.first('message')"
                        >
                            <b-form-textarea
                                rows="10"
                                id="message"
                                v-model="formFields.message"
                                type="text"
                                :state="((formErrors.has('message') ? false : null))"
                            ></b-form-textarea>
                        </b-form-group>
                    </b-col><!--/b-col-->
                </b-row>
                <hr/>
                <b-row class="operation-footer">
                    <b-col sm="12">
                        <b-button
                            size="sm"
                            type="submit"
                            variant="primary"
                            :disabled="global.pendingRequests > 0"
                        >
                            <i v-show="global.pendingRequests > 0"
                               class="fa fa-spin fa-spinner"></i>
                            <i class="fa fa-save fa-sm"></i>
                            Save
                        </b-button>

                        <b-button variant="danger" class="ml-3"
                                  size="sm"
                                  @click="handleOperationClose()">
                            <i class="fa fa-arrow-left fa-sm mr-1"></i> Cancel
                        </b-button>
                    </b-col>
                </b-row>
            </form>
        </b-row>
    </b-container>
</template>

<script>
    import Error from "@/core/services/Error";
    import {request} from "@/core/services/Request";
    import {mapState} from 'vuex'

    const DEFAULT_FORM_STATE = {
        name: null,
        email: null,
        message: null,
        subject: null,
        phone: null,
        _method: 'post',
    };

    export default {
        data() {
            return {
                formFields: {...DEFAULT_FORM_STATE},
                formErrors: new Error({}),
            }
        },
        methods: {
            async handleSubmitOperation() {
                this.formErrors = new Error({})
                try {
                    const response = await request({
                        url: 'contacts/create',
                        method: 'post',
                        data: this.formFields,
                    })

                    this.$global.contactSuccess()

                    this.formFields = {...DEFAULT_FORM_STATE}

                } catch (error) {
                    if (error.request && error.request.status && error.request.status === 422) {
                        this.formErrors = new Error(JSON.parse(error.request.responseText).errors)
                        return false
                    }
                    this.$global.handleServerError(error)
                }

            },

        },
        computed: {
            ...mapState([
                'global',
            ]),
        },
    }
</script>
